import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import { APIURL } from '../services/addresses'

export default function Matches({ teamData, draft }) {
  const [matchData, setMatchData] = useState([])

  useEffect(async () => {
    await axios.get(`${APIURL}/nhl/matches`)
      .then(res => setMatchData(res.data.filter(d => {
        return ((new Date(d.DateTime).getTime() + 25200000) > new Date().getTime())
      }
      )))
      .catch(e => console.log(e))
  }, [])

  return (
    <>
      {matchData.length > 0 ?
        <div>
          <h5>Seuraavat pelit</h5>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Koti</th>
                <th></th>
                <th>Pelaaja</th>
                <th></th>
                <th>Pelaaja</th>
                <th></th>
                <th>Vieras</th>
              </tr>
            </thead>
            <tbody>
              {matchData.map(m => {
                let h = draft.teamsChosen.find(e => e.team.Key === m.HomeTeam)
                let a = draft.teamsChosen.find(e => e.team.Key === m.AwayTeam)
                let hData = teamData.find(t => t.Key === m.HomeTeam)
                let aData = teamData.find(t => t.Key === m.AwayTeam)

                return (
                  <tr key={m.GameID}>
                    <td>{new Date(m.DateTime).toLocaleString('fi-FI')}</td>
                    <td>{hData.City} {hData.Name}</td>
                    <td>
                      <img
                        alt={hData.Key}
                        src={hData.WikipediaLogoUrl}
                        height='20px'
                      />
                    </td>
                    <td>{h ? h.user.name : ''}</td>
                    <td>vs</td>
                    <td>{a ? a.user.name : ''}</td>
                    <td>
                      <img
                        alt={aData.Key}
                        src={aData.WikipediaLogoUrl}
                        height='20px'
                      />
                    </td>
                    <td>{aData.City} {aData.Name}</td>
                  </tr>
                )
              })}
            </tbody>

          </Table>
        </div> : ''
      }
    </>
  )
}